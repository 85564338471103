import React from 'react'
import { graphql } from 'gatsby'
import { HomePageTemplate } from '../templates/HomePage'
require('typeface-oswald')

export default class IndexPage extends React.Component {
  render() {
    const { frontmatter } = this.props.data.markdownRemark

    return (
      <HomePageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        legal={frontmatter.legal}
        socialMedia={frontmatter.socialMedia}
        downloadLinks={frontmatter.downloadLinks}
        hero={frontmatter.hero}
      />
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    markdownRemark(frontmatter:{templateKey: {eq: "HomePage"}}) {
      frontmatter {
        title
        description
        legal {
          label
          value
        }
        socialMedia {
          icon
          url
        }
        downloadLinks {
          title
          links {
            icon
            url
            altText
          }
        }
        hero {
          title
          description
          avatar
          backgroundImage
          mainImages {            
            image
          }
        }
      }
    }
  }
`
